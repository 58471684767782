import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, ToastService} from '../../../@core/utils';

@Component({
  selector: 'ngx-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss'],
})
export class BranchListComponent {


  @Input()
  serial: string = '1';
  @Input()
  branches: any[] = [];
  @Input()
  userId: number;

  constructor(private http: AuthService, private toaster: ToastService, private router: Router) {
  }


  async addBranch(event) {
    try {
      await this.http.create({
        __action: 'add',
        branch_id: event.id,
        user_id: this.userId,
      }, {}, 'user_branch_association');
      if (!this.branches) {
        this.branches = [];
      }
      this.branches.push(event);
      this.toaster.showToast('Successfully added user.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error adding user ', 'Error', true, e);
    }
  }

  async removeBranch(id: number, index: number) {
    try {
      await this.http.create({
        __action: 'remove',
        branch_id: id,
        user_id: this.userId,
      }, {}, 'user_branch_association');
      this.branches.splice(index, 1);
      this.toaster.showToast('Successfully removed user.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing user ', 'Error', true, e);
    }
  }

  editBranch(id?: number) {
    this.router.navigate(['/pages/management/base/branch/' + (id ? id.toString(10) : 'new')]);
  }

}
