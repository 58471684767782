import {AfterViewInit, Component, Input} from '@angular/core';
import * as L from 'leaflet';
import {icon, Marker, marker} from 'leaflet';
import 'leaflet-draw';
import {NbDialogService} from '@nebular/theme';
import {environment} from '../../../../environments/environment';
import {DataService} from '../../../@core/utils';
import {DialogComponent} from '../dialog/dialog.component';
import { Zone } from '../../../@core/models/Zone';
import {Outlet} from "../../../@core/models/Outlet";

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements AfterViewInit {


  map: L.Map;
  options = {
    layers: [
      L.tileLayer('https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=' +
        environment.hereApiKey, {
        maxZoom: 18,
        attribution: '',

      }),
    ],
    // measureControl: true,
    fullscreenControl: true,
    zoomControl: true,
    zoom: 12,
    center: L.latLng(28.7163, 77.1563),
  };

  drawItems = new L.FeatureGroup();

  drawOptions: any = {
    position: 'bottomright',
    draw: false,
    edit: {
      featureGroup: this.drawItems,
    },
  };

  @Input() zone: Zone;
  @Input() outlets: Outlet[] = [];
  @Input() serial: string;

  constructor(private dialogService: NbDialogService, private http: DataService) {
  }



  ngAfterViewInit(): void {
    this.addLayer();
  }

  addLayer() {
    if (this.zone && 'geom' in this.zone) {
      const layer = L.geoJSON(this.zone.geom, {});
      this.map.addLayer(layer);
      const bounds = L.geoJSON(this.zone.geom).getBounds();
      this.map.fitBounds(bounds);
    }
    if (this.outlets && this.outlets.length) {
      const markers: Marker[] = [];
      this.outlets.filter(o => o.address).forEach((outlet) => {
        const m = marker([outlet.address.geom.latitude, outlet.address.geom.longitude], {
          icon: icon({
            iconSize: [25, 25],
            iconAnchor: [20, 0],
            iconUrl: 'assets/images/merchant.png',
          }),
        }).bindTooltip(outlet.name, {
          permanent: true,
          direction: 'top',
          className: 'tooltipStyle',
        }).openTooltip();
        m.addTo(this.map);
        markers.push(m);
        this.map.fitBounds(markers.map(i => [i.getLatLng().lat, i.getLatLng().lng]));
      });
    }
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.map.addLayer(this.drawItems);
    const self = this;
    this.map.on(L.Draw.Event.EDITED, (e: any) => {
      const layers = e.layers;
      layers.eachLayer(async function (layer: any) {
        const featureGroup = L.featureGroup();
        featureGroup.addLayer(layer);
        const data: any = featureGroup.toGeoJSON();
        try {
          await self.http.update(data.features[0].properties.fenceId, {geom: data}, {}, 'zone');
        } catch (e) {
        }
      });

    });

    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      const layer = e.layer;
      this.dialogService.open(DialogComponent, {
        context: {
          title: 'Enter GeoFence Name',
          body: '',
          type: 'prompt',
        },
      }).onClose.subscribe(async res => {
        if (res) {
          const featureGroup = L.featureGroup();
          featureGroup.addLayer(layer);
          const data: any = featureGroup.toGeoJSON();
          await this.http.create({geom: data, name: res, type: e.layerType}, {}, 'zone');
        } else {
          this.map.removeLayer(e.layer);
        }
      });
    });
  }

}
