import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NbMenuService, NbSidebarService} from '@nebular/theme';

import {environment} from '../../../../environments/environment';
import {DataService, UserService} from '../../../@core/utils';
import {Auth, Merchant} from "../../../@core/models/User";
import {Outlet} from "../../../@core/models/Outlet";
import {NbAuthService} from "@nebular/auth";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header', styleUrls: ['./header.component.scss'], templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input() position = 'normal';

  user: Auth;
  page: number = 1;
  events: any[] = [];
  loading: boolean = false;
  userMenu = [{title: 'Logout'},];
  placeholders: any[] = [];
  pageSize: number = 50;
  outlets: Outlet[] = [];
  store_logo: string;
  currentApplicationVersion = environment.appVersion;

  profilePic = '';
  merchantInfo: Merchant;

  constructor(private sidebarService: NbSidebarService, private menuService: NbMenuService, private router: Router,
              public userService: UserService, private cd: ChangeDetectorRef, private http: DataService,
              private nbAuth: NbAuthService) {
  }

  async ngOnInit() {
    this.menuService.onItemClick()
      .subscribe(async event => {
        if (event.item.title.toString().toLowerCase().trim() === 'logout') {
          this.userService.logOut();
        } else if (event.item.title.toString() === 'Add Money') {
          await this.router.navigate(['/pages/wallet/dashboard'])
        } else if (event.item.title.toString() === 'Wallet Statement') {
          await this.router.navigate(['/pages/wallet/wallet-statement'])
        }
      });
      this.getMerchantPic().then();
    this.user = this.userService.user;
    setTimeout(() => {
      this.outlets = this.userService.outlets;
      this.userService.outlets$.subscribe(res => {
        this.outlets = res;
      });
    }, 2000);
    this.userService.user$.subscribe(res => {
      this.user = res;
      this.cd.detectChanges();
    });
    if(!Object.keys(this.user).length){
      this.user=await this.userService.getCompanyData();
    }
  }

  async getMerchantPic() {
    try {
      this.nbAuth.getToken().subscribe(async (res) => {
        if(res.getPayload() !== null){
          this.merchantInfo = res.getPayload()['identity'].merchant;
          if (!this.userService.merchantInfo['id']) {
            await this.userService.getMerchant(this.merchantInfo.id);
          }
          await this.userService.checkMerchantWalletAccess(this.merchantInfo.id);
          if (this.userService.enableMerchantWallet) {
            this.userMenu.unshift({title: 'Add Money'},{title: 'Wallet Statement'});
          }
          const picture = this.userService.merchantInfo['picture'] ? this.userService.merchantInfo['picture'] : '';
          this.profilePic = picture !== null ? picture : this.store_logo;
          this.cd.detectChanges();
        }

      });

    } catch (e) {
      console.error(e);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    
    this.cd.detectChanges();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  updateUrl($event: any) {
    $event.target.src = 'assets/images/1.svg';
  }

  async loadNext() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      this.placeholders = new Array<number>(this.pageSize);
      const events = await this.http.query({
        __order_by: '-created_on', __limit: this.pageSize,
      }, 'event', 'event');
      this.loading = false;
      this.events = events.data;
      this.placeholders = [];
    } catch (e) {

    }
  }
}
