<nb-card>
  <nb-card-header>{{serial}}. Address</nb-card-header>
  <nb-card-body class="p-4">
    <form #addressForm=ngForm class="form form-horizontal">
      <div class="form-group">
        <label>Address</label>
        <input #addressModel=ngModel [(ngModel)]="address.address" [disabled]="!id" fullWidth minlength="4"
               name="address"
               nbInput required="true"
               shape="rectangle" status="primary" type="text">

        <div *ngIf="addressModel.invalid && (addressModel.dirty || addressModel.touched)"
             class="alert alert-danger mt-3">

          <div *ngIf="addressModel.errors.required">
            Address is required.
          </div>
          <div *ngIf="addressModel.errors.minlength">
            Address must be at least 8 characters long.
          </div>

        </div>

      </div>

      <div class="form-group">
        <label>Google</label>
        <input #addressGoogle=ngModel [(ngModel)]="address.google_address" [disabled]="!id" [hidden]="true"
               fullWidth minlength="6"
               name="email" nbInput shape="rectangle"
               status="primary"
               type="text">

        <ngx-google-search (emitAddress)="setAddress($event)"
                           [inputAddress]="address.google_address"></ngx-google-search>

        <div *ngIf="addressGoogle.invalid && (addressGoogle.dirty || addressGoogle.touched)"
             class="alert alert-danger mt-3">

          <div *ngIf="addressGoogle.errors.required">
            Google address is required.
          </div>
          <div *ngIf="addressGoogle.errors.minlength">
            Google address must be more than 6 characters
          </div>

        </div>

      </div>

      <div class="form-group">
        <label>Latitude</label>
        <input #addressLatitude=ngModel [(ngModel)]="address.geom.latitude" [disabled]="!id" fullWidth
               name="latitude"
               nbInput required="true" shape="rectangle"
               status="primary" type="text">


        <div *ngIf="addressLatitude.invalid && (addressLatitude.dirty || addressLatitude.touched)"
             class="alert alert-danger mt-3">

          <div *ngIf="addressLatitude.errors.required">
            latitude is required.
          </div>
          <div *ngIf="addressLatitude.errors.pattern">
            invalid format
          </div>

        </div>
      </div>

      <div class="form-group">
        <label>Longitude</label>
        <input #addressLongitude=ngModel [(ngModel)]="address.geom.longitude" [disabled]="!id" fullWidth
               name="longitude"
               nbInput required="true" shape="rectangle"
               status="primary" type="text">


        <div *ngIf="addressLongitude.invalid && (addressLongitude.dirty || addressLongitude.touched)"
             class="alert alert-danger mt-3">

          <div *ngIf="addressLongitude.errors.required">
            latitude is required.
          </div>
          <div *ngIf="addressLongitude.errors.pattern">
            invalid format
          </div>

        </div>
      </div>

      <div class="form-group">
        <label>City</label>
        <input [(ngModel)]="address.city" [disabled]="!id" fullWidth
               name="city"
               nbInput required="false" shape="rectangle"
               status="primary" type="text">

      </div>

      <div class="form-group">
        <label>State</label>
        <input [(ngModel)]="address.state" [disabled]="!id" fullWidth
               name="state"
               nbInput required="false" shape="rectangle"
               status="primary" type="text"/>

      </div>

      <div class="form-group">
        <label>Country</label>
        <input [(ngModel)]="address.country" [disabled]="!id" fullWidth
               name="country"
               nbInput required="false" shape="rectangle"
               status="primary" type="text"/>

      </div>


    </form>
  </nb-card-body>

  <nb-card-footer>
    <div class="d-flex justify-content-end">
      <button class="mr-5" nbButton status="danger">CANCEL</button>
      <button (click)="save()" [disabled]="addressForm.form.invalid" nbButton status="primary">SAVE</button>
    </div>
  </nb-card-footer>
</nb-card>
