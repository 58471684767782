import { Injectable } from '@angular/core';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  domainName: string;
  logoURL: string;
  roadcastURL: string;
  theme: string;
  logo: string;
  title: string;
  favIcon: string;
  login_slide_1: any;
  login_slide_2: any;
  login_slide_3: any;

  constructor(private http: DataService) { }

  setDomainName() {
    this.domainName = window.location.hostname;
  }

  async init() {
    const logoImgSetting = await this.http.query({}, 'web/settings', 'auth');
    this.theme = logoImgSetting ? logoImgSetting.theme : '#10C5B3';
    if (logoImgSetting && logoImgSetting.logo) {
      this.roadcastURL = logoImgSetting.logo;
      this.logoURL = logoImgSetting.logo;
      this.title = logoImgSetting.title;
      this.favIcon = logoImgSetting.fav_icon || 'https://res.cloudinary.com/roadcast/image/upload/v1614585631/synco/favicon/favicon_nhwrpt.ico';
    } else {
      this.title = 'Synco-Roadcast';
      this.roadcastURL = '../../../assets/images/1.svg';
      this.logoURL = '../../../assets/images/' + this.domainName + '.svg';
      this.favIcon = 'https://res.cloudinary.com/roadcast/image/upload/v1614585631/synco/favicon/favicon_nhwrpt.ico';
    }
    this.login_slide_1 = logoImgSetting?.login_slide_1;
    this.login_slide_2 = logoImgSetting?.login_slide_2;
    this.login_slide_3 = logoImgSetting?.login_slide_3;
    this.setDomainName();
  }
}
