<div class="row align-items-center justify-content-center mb-15 tab-fil-cont">
  <div class="col-5 m-mb-10 ult"><h3 class="font20 mb-0">{{reportName}} <span
    class="bolder">{{count ? '(' + count + ')' : ''}}</span></h3></div>
  <div class="col-7 d-flex align-items-center justify-content-end w-select ulf">

    <span *ngIf="addNew">
			<button (click)="edit()" nbButton size="small" status="primary" class="smb ml-10">Create New</button>
		</span>

    <span *ngIf="dateFilter" class="datecont-n ml-10">
      <input #inputVal [nbDatepicker]="formpicker" nbInput placeholder="Pick Date Range" class="form-control bg-white">
      <nb-rangepicker #formpicker (rangeChange)="changeRange($event)"></nb-rangepicker>
      <i (click)="changeRange(null); inputVal.value = ''" class="eva-icon-container"><nb-icon
        icon="close"></nb-icon></i>
    </span>

    <span>
      <ng-container *ngFor="let filter of filters">
        <nb-checkbox #checked (change)="applyFilter(filter, checked?.value)"><span>{{filter.name}}</span></nb-checkbox>
    </ng-container>
    </span>

    <span class="mr-10" *ngIf="searchField">
      <input (keyup.enter)="loadData()" [(ngModel)]="searchTerm" fieldSize="medium" nbInput placeholder="Search here"
             type="text" class="form-control">
    </span>

    <!-- Filters Button -->
    <span class="ml-10">
       <ng-container *ngIf="searchFields.length">
          <button #pop1="ngbPopover" (click)="closePopup=!closePopup" [autoClose]="false" [disablePopover]="closePopup"
                  [ngbPopover]="filterTemp" [placement]="'bottom-right'" [popoverClass]="'pop'" nbButton
                  status="primary" class="filter-btn">
            <nb-icon icon="options-2-outline"></nb-icon>
          </button>

         <!-- Filters Pop Up -->
			<ng-template #filterTemp>
				<div class="p-3 row d-flex align-items-center flex-column">
					<ng-container *ngFor="let field of searchFields">
						<ng-container *ngIf="field?.type === 'typeahead';else noTypeAhead">
							<div class="col-12 mb-10">
								<app-type-ahead (cleared)="fieldName=''" (send)="field.term = $event.id;  this.fieldName = $event.name"
                                [basePath]="field.base ? field.base : 'auth'"
                                [filters]="field.filters"
                                [placeholder]="fieldName && field.term ? fieldName : field.name"
                                [url]="field?.path">
                </app-type-ahead>
							</div>
						</ng-container>
            <ng-template #noTypeAhead>
              <div class="col-12 mb-10" *ngIf="field.type !== 'date' && field.type !== 'date-range-start' &&
							field.type !== 'date-range-end'">
                <input [(ngModel)]="field.term" [placeholder]="field.name" class="col-12" nbInput type="text">
              </div>
              <div *ngIf="field.type === 'date'" class="col-12 mb-10">
                <input [(ngModel)]="field.term" [placeholder]="field.name" class="col-12" nbInput type="date">
              </div>
              <div *ngIf="field.type === 'date-range-start'" class="col-12 mb-10">

              </div>
              <div *ngIf="field.type === 'toggle'" class="col-12">
                <nb-checkbox [(checked)]="field.term">{{field.name}}</nb-checkbox>
              </div>
            </ng-template>
		</ng-container>
					<div class="col-12 w-100 mt-15 text-center">
						<button (click)="search(true);" nbButton size="small" status="primary" class="mr-15">Clear</button>
						<button (click)="search()" nbButton size="small" status="primary">Apply</button>
					</div>
				</div>
			</ng-template>
		</ng-container>
		</span>
  </div>
</div>

<!-- Table Data -->
<div class="table-responsive">
  <table class="table table-bordered table-striped">
    <thead>
    <tr class="font-weight-bold">
      <th class="white_space" *ngFor="let column of columns">{{ column?.displayName }}</th>
      <th class="white_space" *ngIf="editIcon">Edit</th>
      <th class="white_space" *ngIf="infoIcon">View</th>
      <th class="white_space" *ngIf="disableIcon">Disable</th>
    </tr>
    </thead>
    <tbody class="fixed-height">
    <ng-container *ngFor="let row of data">
      <tr>
        <ng-container *ngFor="let column of columns">
          <td [innerHTML]="column.displayFn ? column.displayFn(row, column) : row[column.name]"></td>
        </ng-container>

        <td *ngIf="editIcon">
          <div (click)="edit(row.id)" class="icon">
            <i class="eva-icon-container">
              <nb-icon
                [icon]="'edit-outline'"></nb-icon>
            </i>
          </div>
        </td>
        <td *ngIf="infoIcon">
          <div (click)="view.emit(row)" class="icon">
            <i class="eva-icon-container">
              <nb-icon
                [icon]="'info-outline'"></nb-icon>
            </i>
          </div>
        </td>
        <td *ngIf="disableIcon">
          <div (click)="disable(row, disableColumn)" class="icon cursor-pointer">
            <i class="eva-icon-container">
              <ng-container *ngIf="row[disableColumn]; else disabled">
                <nb-icon [icon]="'toggle-right-outline'"
                         [options]="{ animationType: 'pulse', fill: 'green', width: 24, height: 24 }"></nb-icon>

              </ng-container>
              <ng-template #disabled>
                <nb-icon [icon]="'toggle-left-outline'"
                         [options]="{ animationType: 'pulse', fill: 'red', width: 24, height: 24 }"></nb-icon>
              </ng-template>
            </i>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<!-- Pagination -->
<div class="paging" *ngIf="footer">
  <div class="row">
    <div class="col-12 text-center">
      <div *ngIf="totalPages > 1" class="d-flex justify-content-center align-items-center font16 nbgbor">
        <div [ngClass]="page === 1? 'disabled':''" class="nicon mr-2">
          <i (click)="previousPage()" class="eva-icon-container previous-page">
            <nb-icon icon="arrow-left-outline"></nb-icon>
          </i>
        </div>
        <div class="font14">
          Page(s): <span class="bold">{{totalPages}}</span>
          <input [(ngModel)]="currPage" [max]="totalPages" [value]="page" class="ml-2 w70px sminp" min="1"
                 nbInput type="number">
          <button (click)="setPage()" class="ml-2 smb1" nbButton status="primary" size="small">Go</button>
        </div>
        <div [ngClass]="page === totalPages? 'disabled':''" class="nicon ml-2">
          <i (click)="nextPage()" class="eva-icon-container next-page">
            <nb-icon icon="arrow-right-outline"></nb-icon>
          </i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 text-left">
    <button (click)="saveAs()" status="primary" nbButton>Download</button>
  </div>
</div>
