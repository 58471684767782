<nb-card>
  <nb-card-header>{{serial}}. Branches</nb-card-header>
  <nb-card-body class="p-4">
    <app-type-ahead (send)="addBranch($event)" [disabled]="!userId" [filters]="{__only: ['id', 'name']}"
                    [model]="{}" [placeholder]="'Search branch here'"
                    [required]="true" [url]="'branch'">

    </app-type-ahead>

    <div class="mt-5">
      <nb-list>
        <nb-list-item *ngFor="let branch of branches; let i = index">
          <div class="d-flex justify-content-between align-items-center">
            <nb-user (click)="editBranch(branch.id)" [name]="branch.name" [title]="branch.phone"
                     class="cursor">

            </nb-user>
            <nb-icon (click)="removeBranch(branch.id, i)" class="cursor" icon="close"
                     status="danger"></nb-icon>
          </div>

        </nb-list-item>
      </nb-list>
    </div>

  </nb-card-body>

  <nb-card-footer class="d-flex justify-content-end">
    <button (click)="editBranch()" nbButton shape="rectangle" status="primary">Add New</button>
  </nb-card-footer>
</nb-card>
