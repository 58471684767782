import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, ToastService} from '../../../@core/utils';
import {Category} from "../../../@core/models/Category";

@Component({
  selector: 'ngx-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent {

  @Input()
  serial: string = '1';
  @Input()
  categories: Category[] = [];
  @Input()
  rateList: number;

  constructor(private http: AuthService, private toaster: ToastService, private router: Router) {
  }


  async addCategory(event) {
    try {
      await this.http.create({
        __action: 'add',
        category_id: event.id,
        rate_list_id: this.rateList,
      }, {}, 'category_rate_list');
      this.categories.push(event);
      this.toaster.showToast('Successfully removed category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing category ', 'Error', true, e);
    }
  }

  async removeCategory(id: any, index: number) {
    try {
      await this.http.create({
        __action: 'remove',
        category_id: id,
        rate_list_id: this.rateList,
      }, {}, 'category_rate_list');
      this.categories.splice(index, 1);
      this.toaster.showToast('Successfully removed category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing category ', 'Error', true, e);
    }
  }

  editCategory(id?: any) {
    this.router.navigate(['/pages/management/base/category/' + (id ? id.toString(10) : 'new')]);
  }

}
