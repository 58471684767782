import {Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {Address, Geom} from '../../../@core/models/User';
import {UserService} from '../../../@core/utils';

declare var google: any;

@Component({
  selector: 'ngx-google-search',
  templateUrl: './google-search.component.html',
  styleUrls: ['./google-search.component.scss'],
})
export class GoogleSearchComponent {
  address: Address = {geom: {} as Geom} as Address;
  @ViewChild('search', {static: true}) searchElementRef: ElementRef;
  @Input()
  inputAddress: string;
  @Input()
  height: number;
  @Input()
  width: number;
  @Output()
  emitAddress: EventEmitter<any> = new EventEmitter();
  @Input() addAddress: string;
  @Input() isEnable = false;
  places_array = ['in'];


  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private userService: UserService) {
    this.userService.getCompanyConfig().then((config) => {
      const places_country = config.find(e => e.key === 'places_country');
      if (places_country) {
        this.places_array = places_country.value;
      }
      this.setAutocomplete();
    });
  }


  clearAddress(event) {
    event.value = null;
    this.address = {} as Address;
    this.emitAddress.emit();
  }


  private setAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: {country: this.places_array},
        types: [],
        fields: ['address_component', 'adr_address', 'business_status', 'formatted_address', 'geometry'],
      });
      const self = this;
      autoComplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: any = autoComplete.getPlace();
          self.address.country = '';
          self.address.city = '';
          self.address.state = '';
          self.address.google_address = this.searchElementRef.nativeElement.value;
          self.address.geom.latitude = place.geometry.location.lat().toString();
          self.address.geom.longitude = place.geometry.location.lng().toString();
          place.address_components.forEach((fa) => {
            if (fa.types.indexOf('country') > -1) {
              self.address.country = fa.long_name;
            }
            if (fa.types.indexOf('administrative_area_level_2') > -1) {
              self.address.city = fa.long_name;
            }
            if (fa.types.indexOf('administrative_area_level_1') > -1) {
              self.address.state = fa.long_name;
            }
          });
          this.emitAddress.emit(self.address);

          const event = new MouseEvent('click', {bubbles: true});
          this.searchElementRef.nativeElement.dispatchEvent(event);
        });
      });
    });

  }
}
