import {Injectable} from '@angular/core';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {DialogComponent} from '../../@theme/components';


@Injectable({
  providedIn: 'root',
})
export class ToastService {
  config = {
    destroyByClick: true,
    duration: 5000,
    preventDuplicates: false,
  };

  constructor(private toastrService: NbToastrService, private dialog: NbDialogService) {
  }

  showToast(message: string, title: string, isError: boolean, error?: HttpErrorResponse) {
    if (isError) {
      if (!(!(error instanceof HttpErrorResponse) || error.ok)) {
        console.error(error, error.status);
      }
      if (error) {
        this.dialog.open(DialogComponent, {
          context: {
            type: 'alert',
            title: error.status ? error.status.toString(10) + ' ' + error.statusText : error.statusText,
            body: error ? error.message : '',
          },
        });
      }
      this.toastrService.danger(message, title, this.config);
    } else {
      this.toastrService.primary(message, title, this.config);
    }

  }
}
