import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule, NbSpinnerModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';



import {RouterModule} from '@angular/router';
import {DataTableComponent} from './components/data-table/data-table.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {TypeAheadComponent} from './components/type-ahead/type-ahead.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {GoogleSearchComponent} from './components/google-search/google-search.component';
import {FilterPipe} from './pipes/filter.pipe';
import {RiderDropdownComponent} from './components/rider-dropdown/rider-dropdown.component';
import {AuthComponent} from './components/auth/auth.component';
import {MapViewComponent} from './components/map-view/map-view.component';

import { OneColumnLayoutComponent } from './layouts';
import { TwoColumnLayoutComponent } from './layouts';
import { ThreeColumnLayoutComponent } from './layouts';
import { CapitalizePipe } from './pipes';
import { NumberWithCommasPipe } from './pipes';
import { PluralPipe } from './pipes';
import { RoundPipe } from './pipes';
import { TimingPipe } from './pipes';
import {DEFAULT_THEME} from "./styles/theme.default";
import {COSMIC_THEME} from "./styles/theme.cosmic";
import {CORPORATE_THEME} from "./styles/theme.corporate";
import {DARK_THEME} from "./styles/theme.dark";
import {
  AddressComponent,
  BranchListComponent,
  CategoryListComponent, CategoryRateListComponent,
  CategorySelectionComponent,
  DialogComponent,
  FileUploadComponent,
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  MerchantListComponent, OutletListComponent, SearchInputComponent, SwitcherComponent, UserListComponent
} from "./components";
import {NgSelectModule} from "@ng-select/ng-select";
import { CeleryTaskStatusDialogComponent } from './components/celery-task-status-dialog/celery-task-status-dialog.component';


const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbListModule,
  NbCheckboxModule,
  NbCardModule,
  NbInputModule,
  NbSelectModule,
  NbDatepickerModule,
  NbCalendarRangeModule,
];

const NG_MODULES = [
  RouterModule,
  Ng2SmartTableModule,
  FormsModule,
  LeafletModule,
  LeafletDrawModule,
];

const NGB_MODULES = [
  NgbModule,
  NgbDropdownModule,
  NgbTypeaheadModule,
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnLayoutComponent,
  TwoColumnLayoutComponent,
  DataTableComponent,
  TypeAheadComponent,
  UserListComponent,
  OutletListComponent,
  BranchListComponent,
  DialogComponent,
  CategorySelectionComponent,
  MerchantListComponent,
  AddressComponent,
  FileUploadComponent,
  GoogleSearchComponent,
  RiderDropdownComponent,
  CategoryListComponent,
  AuthComponent,
  MapViewComponent,
  CategoryRateListComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  FilterPipe,
];


@NgModule({
  imports: [CommonModule, ...NB_MODULES, ...NG_MODULES, ...NGB_MODULES, NgSelectModule, NbSpinnerModule],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, CeleryTaskStatusDialogComponent, ],
  entryComponents: [DialogComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'corporate',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
