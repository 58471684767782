import {Component, Input, OnInit} from '@angular/core';
import {Category} from "../../../@core/models/Category";
import {AuthService, ToastService} from "../../../@core/utils";
import {Router} from "@angular/router";

@Component({
  selector: 'app-category-rate-list',
  templateUrl: './category-rate-list.component.html',
  styleUrls: ['./category-rate-list.component.scss']
})
export class CategoryRateListComponent implements OnInit {


  @Input() serial: string = '1';
  @Input() categories: Category[] = [];
  @Input() outletId: number;

  constructor(private http: AuthService, private toaster: ToastService, private router: Router) {
  }


  async addCategory(event) {
    try {
      await this.http.create({
        __action: 'add',
        category_rate_list_id: event.id,
        outlet_id: this.outletId
      }, {}, 'outlet_category_rate');
      this.categories.push(event);
      this.toaster.showToast('Successfully removed category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing category ', 'Error', true, e);
    }
  }

  async removeCategory(id: any, index: number) {
    try {
      await this.http.create({
        __action: 'remove',
        category_rate_list_id: id,
        outlet_id: this.outletId
      }, {}, 'outlet_category_rate');
      this.categories.splice(index, 1);
      this.toaster.showToast('Successfully removed category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing category ', 'Error', true, e);
    }
  }

  editCategory(id?: any) {
    this.router.navigate(['/pages/management/base/category/' + (id ? id.toString(10) : 'new')]);
  }

  ngOnInit(): void {
  }

}
