import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Column} from "ng2-smart-table/lib/lib/data-set/column";
import {AuthService} from "../../../@core/utils";
import {NbDialogService} from "@nebular/theme";
import {Router} from "@angular/router";
import * as moment from 'moment-timezone';
import {saveAs} from 'file-saver';
@Component({
  selector: 'app-data-table', templateUrl: './data-table.component.html', styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input() columns: Column[] = [];
  @Input() auto: boolean = true;
  @Input() dateFilter: boolean = true;
  @Input() dialogInputColumn: any[] = [];
  @Input() dialogCheckboxColumn: any[] = [];
  @Input() dialogDropdownColumn: any[] = [];
  @Input() filters: any[] = [];
  @Input() only: string[] = [];
  @Input() include: string[] = [];
  @Input() reportName: string;
  @Input() path: string;
  @Input() baseUrl: string = 'auth';
  @Input() editPath: string;
  @Input() addNew = false;
  @Input() orderReport = false;
  @Input() searchIcon = true;
  @Input() footer = true;
  @Input() inputQuery: any = {};
  @Input() infoIcon: boolean = false;
  @Input() editIcon: boolean;
  @Input() disableIcon: boolean;
  @Input() disableColumn: string = '';
  @Input() refresh: boolean = false;
  @Input() searchField: string = '';
  @Input() searchFields: any[] = [];
  @Input() searchTerm: string = '';

  page: number = 1;
  totalPages: any;
  currPage: number;
  limit = 10;
  data: any[] = [];
  query: any = {};
  editData: any = {};
  startDate: any;
  endDate: any;
  test = '';
  @Output() view: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputVal', {static: true}) public formelement: ElementRef;
  closePopup = true;
  startDateFilter: string;
  endDateFilter: string;
  dateFilterRange;
  fieldName: string;
  count: any;

  constructor(private http: AuthService, private router: Router, private nbDialogService: NbDialogService) {
    this.currPage = 1;
    this.totalPages = 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('inputQuery') || (changes.hasOwnProperty('refresh') && changes.refresh.currentValue === true)) {
      this.loadData().then();
    }
  }


  async nextPage() {
    this.currPage = (this.currPage < this.totalPages) ? (this.currPage + 1) : this.currPage;
    this.page = this.currPage;
    await this.loadData();
  }

  async previousPage() {
    this.currPage = (this.currPage > 1) ? (this.currPage - 1) : this.currPage;
    this.page = this.currPage;
    await this.loadData();
  }

  async setPage() {
    this.currPage = (this.currPage > this.totalPages) ? this.totalPages : ((this.currPage <= 0) ? 1 : this.currPage);
    this.page = this.currPage;
    await this.loadData();
  }


  async search(clear?: boolean) {
    if (clear) {
      this.searchFields.forEach(s => {
        if (s.hasOwnProperty('term')) {
          s.term = undefined;
          this.startDateFilter = this.endDateFilter = '';
          this.dateFilterRange = null;
        }
      });
    } else {
      this.closePopup = true;
    }
    this.page = 1;
    this.loadData().then();
  }

  async loadData() {
    try {
      const data = await this.getData();
      this.data = data.data;
      this.count = data.total ? data.total : data.count;
      this.totalPages = Math.ceil(data.total / this.limit);
    } catch (e) {
      this.data = [];
    }
  }


  async getData(): Promise<any> {
    if (this.orderReport) {
      if (!this.startDate || !this.endDate) {
        this.startDate = moment(new Date().setHours(0, 0, 0)).toJSON();
        this.endDate = moment(new Date().setHours(23, 59, 59)).toJSON();
      }
    }
    if (!this.auto && (!this.startDate || !this.endDate)) {
      return;
    }
    this.query.__page = this.page;
    this.query.__only = this.only;
    this.query.__include = this.include;
    this.query.__limit = this.limit;

    if (this.searchField) {
      this.query[this.searchField] = this.searchTerm;
    }
    if (this.searchFields) {
      this.searchFields.forEach(s => {
        if (s.hasOwnProperty('term')) {
          this.query[s.value] = s.term;
        }
      });
    }

    this.query.__created_on__date_gte = this.startDate;

    this.query.__created_on__date_lte = this.endDate;

    for (const i in this.inputQuery) {
      if (this.inputQuery.hasOwnProperty(i)) {
        this.query[i] = this.inputQuery[i];
      }
    }
    return this.http.query(this.query, this.path, this.baseUrl);
  }

  applyFilter(filter: any, value: boolean) {
    if (value) {
      this.query[filter.value] = true;
    } else {
      this.query[filter.value] = undefined;
    }
    this.page = 1;
    this.loadData().then();
  }

  async download() {
    const query = Object.assign({}, this.query);
    query.__export__ = true;
    return this.http.downloadFile(query, this.path, this.baseUrl);
  }

  async saveAs() {
    try {
      const data = await this.download();
      const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
      saveAs(blob, new Date().toISOString().slice(0, 19).replace(/T/, '_') + '.csv');

    } catch (e) {
      return;
    }
  }

  edit(id?: number) {
    this.router.navigate([this.editPath + (id ? id.toString(10) : 'new')]).then();
  }

  async disable(row: any, column: string) {
    const obj = {};
    obj[column] = !row[column];
    await this.http.update(row.id, obj, {}, this.path, this.baseUrl);
    row[column] = !row[column];
  }

  changeRange(event) {
    if (event && event.start && event.end) {
      this.startDate = moment(moment(event.start.toJSON()).format('YYYY-MM-DD') + ' 00:00:01').tz('UTC').toJSON();
      this.endDate = moment(moment(event.end.toJSON()).format('YYYY-MM-DD') + ' 23:59:59').tz('UTC').toJSON();
      this.page = 1;
      this.loadData().then();
    }else if(event==null){
      this.startDate = null;
      this.endDate = null;
      this.page = 1;
      this.loadData().then();
    }
  }

  ngOnInit(): void {
  }
}
