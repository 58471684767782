// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  order: 'https://synco-all-api.roadcast.co.in/api/v1/order/',
  auth: 'https://synco-all-api.roadcast.co.in/api/v1/auth/',
  event: 'https://synco-all-api.roadcast.co.in/api/v1/event/',
  report: 'https://synco-all-api.roadcast.co.in/api/v1/reporting/',
  integrations: 'https://synco-all-api.roadcast.co.in/api/v1/integration/',
  // order: "https://synco-demo.roadcast.net/api/v1/order/",
  // auth: "https://synco-demo.roadcast.net/api/v1/auth/",
  // event: "https://synco-demo.roadcast.net/api/v1/event/",
  // report: "https://synco-demo.roadcast.net/api/v1/reporting/",
  // integrations: "https://synco-demo.roadcast.net/api/v1/integration/",
  // order: 'https://synco-test-api.roadcast.co.in/api/v1/order/',
  // auth: 'https://synco-test-api.roadcast.co.in/api/v1/auth/',
  // event: 'https://synco-test-api.roadcast.co.in/api/v1/event/',
  // report: 'https://synco-test-api.roadcast.co.in/api/v1/reporting/',
  // integrations: 'https://synco-test-api.roadcast.co.in/api/v1/integration/',
  hereApiId: 'I04eWlHjp6ZV31eAQUPY',
  hereApiKey: 'J3OQEveoxMZRtxeftZIw37tYlfs2ax2WfyRu3gJENTk',
  geocode: 'https://api-track.roadcast.co.in/reverse',
  firebaseConfig: {
    apiKey: 'AIzaSyCTks4mDMdTsdtZ2duLTfBCLvFvz7_J_NA',
    authDomain: 'synco-dc96f.firebaseapp.com',
    databaseURL: 'https://synco-dc96f.firebaseio.com',
    projectId: 'synco-dc96f',
    storageBucket: 'synco-dc96f.appspot.com',
    messagingSenderId: '396410044695',
    appId: '1:396410044695:web:b349e9b39770b377',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
