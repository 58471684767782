import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-one-column-layout',
  templateUrl: './one-column.layout.component.html',
  styleUrls: ['./one-column.layout.component.scss']
})
export class OneColumnLayoutComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;

  constructor() { }

  ngOnInit(): void {
  }

}
