<!--<div>-->
<!--  <div [container]="'body'" [display]="'static'" [open]="!searchFailed" class="input-group" ngbDropdown>-->
<!--    <input (ngModelChange)="searchApi($event)" [(ngModel)]="text" [disabled]="disabled" [placeholder]="placeholder"-->
<!--           [value]="resultFormatter(model)" (click)="searchApi('')"-->
<!--           class="form-control" fullWidth nbInput shape="rectangle" status="primary" type="text">-->
<!--    <div (click)="searchFailed=!searchFailed" class="input-group-append"-->
<!--         style="position: absolute;right: 0;z-index: 999">-->
<!--    <span class="input-group-text" style="margin: 1px">-->
<!--      <nb-icon *ngIf="!searchFailed" icon="chevron-down-outline"></nb-icon>-->
<!--      <nb-icon *ngIf="searchFailed" icon="chevron-up-outline"></nb-icon>-->
<!--    </span>-->
<!--    </div>-->


<!--    <div aria-labelledby="dropdownForm1" ngbDropdownMenu-->
<!--         style="width: 100%; height: 250px;overflow-y: scroll;position: unset">-->
<!--      <nb-list-->
<!--      >-->
<!--        <nb-list-item *ngFor="let result of results">-->
<!--          <div (click)="emitSelected(result)" class="text-dark" style="cursor: pointer">-->

<!--            {{ resultFormatter(result) }}-->


<!--          </div>-->
<!--        </nb-list-item>-->

<!--      </nb-list>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->
<div [nbSpinnerMessage]="'Please wait!!'" [nbSpinnerSize]="'large'" [nbSpinner]="spinner"
     class="w-100">

  <ng-select (change)="emitSelected($event)"
             [clearSearchOnAdd]="true"
             [closeOnSelect]="true"
             [items]="data$ | async"
             (open)="loadPeople()"
             [loading]="dataLoading"
             (search)="failed.emit($event.term);searchApi($event)"
             [minTermLength]="1"
             [multiple]="multiple"
             (clear)="cleared.emit('all')"
             [typeahead]="dataInput$"
             [placeholder]="placeholder || 'Search Here'"
             appendTo="body"
             [(ngModel)]="selectedItems"
             [bindLabel]="text">

    <ng-template let-item="item" ng-option-tmp>
      {{ resultFormatter(item) }}
    </ng-template>

    <!--		<ng-template ng-footer-tmp>-->
    <!--			<button (click)="selectAll()" nbButton size="small" status="control">Select all</button>-->
    <!--		</ng-template>-->

  </ng-select>

</div>
