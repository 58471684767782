import {Component, Input, OnDestroy} from '@angular/core';
import {NbLayoutDirection, NbLayoutDirectionService} from "@nebular/theme";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-layout-direction-switcher',
  templateUrl: './layout-direction-switcher.component.html',
  styleUrls: ['./layout-direction-switcher.component.scss']
})
export class LayoutDirectionSwitcherComponent implements OnDestroy {

  directions = NbLayoutDirection;
  currentDirection: NbLayoutDirection;
  @Input() vertical: boolean = false;
  protected destroy$ = new Subject<void>();

  constructor(private directionService: NbLayoutDirectionService) {
    this.currentDirection = this.directionService.getDirection();

    this.directionService.onDirectionChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe(newDirection => this.currentDirection = newDirection);
  }

  toggleDirection(newDirection: any) {
    this.directionService.setDirection(newDirection);
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
