<nb-layout>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start state="compacted" fixed="false">
    <ng-content select="nb-menu"></ng-content>
    <div class="version-m font10 normal text-center pb-10 color-grey">v{{currentApplicationVersion}}</div>
  </nb-sidebar>

  <nb-layout-column class="pagepad">
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
