<label class="switch-label" [class.vertical]="vertical">
      <span class="first" [class.active]="vertical || isFirstValue()">
        {{vertical ? currentValueLabel() : firstValueLabel}}
      </span>

  <div class="switch">
    <input type="checkbox" [checked]="isSecondValue()" (change)="changeValue()">
    <span class="slider"></span>
  </div>

  <span *ngIf="!vertical"
        class="second"
        [class.active]="isSecondValue()">
          {{secondValueLabel}}
      </span>
</label>
