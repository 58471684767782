import {Injectable, OnDestroy} from '@angular/core';
import {HttpService} from '../../config/http.service';
import {HttpClient} from '@angular/common/http';
import {interval, Subject, Subscription} from 'rxjs';
import {BnNgIdleService} from 'bn-ng-idle';
import {DataService} from './data.service';


@Injectable()
export class OrderService extends HttpService<any> implements OnDestroy {

  orders$: Subject<any> = new Subject() as Subject<any>;
  orders: any[] = [];
  public lastUpdatedOn: Date = new Date();
  sub: Subscription;
  private initial = true;
  outletId: string;
  startDate: any;
  endDate: any;
  queryData: {};
  today: Date = new Date();
  constructor(http: HttpClient, private bnIdle: BnNgIdleService, private httpp: DataService) {
    super(http, {
      path: 'order',
    });
    // this.resetTime();
    this.today.setHours(this.today.getHours() - 124);
  }

  resetTime() {
    this.lastUpdatedOn.setHours(this.lastUpdatedOn.getHours() - 24);
  }


  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async init() {
    this.resetTime();
    this.destroy();
    const orders = await this.getOrders();
    this.orders = orders.orders;
    this.orders$.next(this.orders);
    this.sub = interval(15000)
      .subscribe(() => {
        this.getOrders().then((res) => {
          this.orders$.next(res.orders);
        });
      });
  }

  async getDateRangeOrders() {
    this.destroy();
    const orders = await this.getOrders();
    this.orders = orders.orders;
    this.orders$.next(this.orders);
  }

  async getOrders(): Promise<{ orders: any[], initial: boolean }> {
    const currentTime: Date = new Date();
    currentTime.setSeconds(currentTime.getSeconds() - 1);
    return new Promise(async (resolve, reject) => {
      try {
        if (this.startDate && this.endDate) {this.queryData = {last_updated_on: this.startDate,
          __outlet_id__equal: this.outletId, __created_on__datetime_gte: this.startDate,
          __created_on__datetime_lte: this.endDate,
        };
        } else {
          this.queryData = {
            last_updated_on: this.lastUpdatedOn.toJSON(),
            __created_on__datetime_gte: (new Date()).toJSON(),
            __outlet_id__equal: this.outletId,
          };
        }
        const res = await this.query(this.queryData, 'order_polling', 'order');
        this.lastUpdatedOn = currentTime;
        resolve({orders: res.data, initial: this.initial});
        this.initial = false;
      } catch (e) {
        reject(e);
        console.error(e);
      }
    });
  }

  destroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
