import {Inject, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService, NbAuthToken} from '@nebular/auth';
import {switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter,
              private router: Router) {
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {

      return this.authService.isAuthenticated()
        .pipe(
          switchMap(authenticated => {
            if (authenticated) {
              return this.authService.getToken().pipe(
                switchMap((token: NbAuthToken) => {
                  const JWT = token.getValue();
                  req = req.clone({
                    setHeaders: {
                      Authorization: JWT,
                    },
                  });
                  return next.handle(req)
                    .pipe(tap((t: any) => {
                      },
                      (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                          if (err.status !== 401) {
                            return;
                          }
                          this.authService.logout('email').subscribe(() => {
                            localStorage.removeItem('auth_app_token');
                            location.reload();
                          }, () => {
                            localStorage.removeItem('auth_app_token');
                            location.reload();
                          });
                          return;
                        }
                      }));
                }),
              );
            } else {
              // Request is sent to server without authentication so that the client code
              // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
              return next.handle(req)
                .pipe(tap((t: any) => {
                  },
                  (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      if (err.status !== 401) {
                        return;
                      }
                      this.authService.logout('email').subscribe(() => {
                        localStorage.removeItem('auth_app_token');
                        location.reload();
                      }, () => {
                        localStorage.removeItem('auth_app_token');
                        location.reload();
                      });
                      return;
                    }
                  }));
              // return next.handle(req);
            }
          }),
        );
    } else {
      return next.handle(req);
    }
  }
}
