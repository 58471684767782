<div [class.left]="position === 'normal'" [class.right]="position === 'inverse'" class="header-container col-sm-4 pl-0">
  <!-- Menu -->
  <div>
    <a (click)="toggleSidebar()" class="navigation d-cursor">
      <img src="/assets/images/menu.svg" alt="" width="28">
    </a>
  </div>
</div>

<ng-container>
  <!-- Company Logo -->
  <div class="col-sm-4 header-container c-logo-inner pl-0 pr-0">
    <img [src]="user?.company?.icon?user?.company?.icon:user['icon']" class="d-block mx-auto" alt="">
  </div>

  <div class="col-sm-4 header-container right pr-0">
    <nb-actions size="medium" class="no-disp">
      <nb-action class="control-item">
        <nb-user [name]="merchantInfo?.name"
                  [title]="userService.enableMerchantWallet ? ('Wallet Balance: &#8377;' + (userService.merchantWallet['amount'] ?
                   userService.merchantWallet['amount'].toFixed(2) : 0)) : ''"
                 [nbContextMenu]="userMenu"
                 [picture]="profilePic" nbContextMenuTag="my-context-menu"></nb-user>
      </nb-action>
    </nb-actions>
    <!--    <div class="mx-3 versionShow" data-toggle="tooltip" data-placement="bottom"-->
    <!--         title="Ctrl + Shift + r (To Check for Updates)">-->
    <!--      v{{currentApplicationVersion}}-->
    <!--    </div>-->
  </div>
</ng-container>
