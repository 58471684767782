<nb-card>
  <nb-card-header>{{serial}}. Categories</nb-card-header>
  <nb-card-body class="p-4">
    <div class="row">
      <ng-container *ngFor="let category of (categories|async)?.data">
        <nb-checkbox (checkedChange)="changeStatus(category.id, $event)" [checked]="checkStatus(category.id)"
                     class="col-4">
          {{category.name}}
        </nb-checkbox>
      </ng-container>
    </div>


  </nb-card-body>
</nb-card>
