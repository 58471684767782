import {Component, Inject, OnInit} from '@angular/core';
import {AnalyticsService, UserService} from "./@core/utils";
import {DOCUMENT} from "@angular/common";
import {ConstantsService} from "./@core/utils/constants.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService,  @Inject(DOCUMENT) private _document: HTMLDocument,
              public constantsService: ConstantsService, private userService: UserService) {
    this.constantsService.init().then(() => {
      const faviconElement = document.getElementById('appFavicon');
      if (faviconElement) {
        faviconElement.setAttribute('href', this.constantsService.favIcon);
      }
    });
  }

  ngOnInit() {
    this.analytics.trackPageViews();
    const companyColor =  this.constantsService.theme ? this.constantsService.theme : '#10c5b3';
    document.documentElement.style.setProperty('--basecolor', companyColor);
  }
}
