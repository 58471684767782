import {Injectable} from '@angular/core';
import {HttpService} from '../../config/http.service';
import {HttpClient} from '@angular/common/http';
import {NbAuthService, NbAuthToken} from '@nebular/auth';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {MessagingService} from './messaging.service';
import {Outlet} from '../models/Outlet';
import {Auth} from '../models/User';
import {Status} from '../models/Order';
import {DataService} from "./data.service";


@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpService<any> {
  user: Auth = {} as Auth;
  redirectUrl: string;
  statuses: any;
  outlets: Outlet[] = [];
  user$: Subject<any> = new Subject() as Subject<any>;
  outlets$: Subject<any> = new Subject() as Subject<any>;
  statuses$: Subject<any> = new Subject() as Subject<any>;
  companyData: any;
  isToken = false
  merchantWallet = {};
  merchantInfo = {};
  companyConfig: any = [];
  enableMerchantWallet: boolean = false;
  constructor(private httpClient: HttpClient, private router: Router, private nbAuth: NbAuthService,
              private messagingService: MessagingService, private https: DataService) {
    super(httpClient, {
      path: '/user',
    });
    this.nbAuth.getToken().subscribe(async (res) => {
      if(res.getPayload() !== null){
        const identity = res.getPayload()['identity'];
        await this.init(identity);
        this.messagingService.requestPermission(this.user.id);
      }
    });
  }

  getUser$(): Observable<any> {
    return this.user$.asObservable();
  }

  async init(identity: any) {
    try {
      this.user = identity;
      await this.getMerchant(this.user.merchant.id)
      await this.checkMerchantWalletAccess();
      this.user$.next(this.user);
      this.outlets = await this.getOutlets();
      this.statuses = await this.getStatuses();
      this.statuses$.next(this.statuses);
      this.outlets$.next(this.outlets);
    } catch (e) {
      console.error(e);
      this.logOut();
    }
  }

  async getOutlets(): Promise<any> {
    return (await this.query({
      __only: ['id', 'name', 'address', 'zone_id', 'prep_time', 'zone', 'mobile_number', 'is_premium', 'brand'],
    }, 'outlet', 'auth')).data;
  }

  logOut() {
    this.nbAuth.logout('email').subscribe(() => {
      localStorage.removeItem('auth_app_token');
      location.reload();
    }, () => {
      localStorage.removeItem('auth_app_token');
      location.reload();
    });
  }

  isTokenExpired() {
    return this.nbAuth.getToken().subscribe((token: NbAuthToken) => {
      if(token.getPayload() !== null){
        const currentDate = new Date().getTime();
        const expirationDate = new Date(token.getPayload().exp * 1000).getTime();
        this.isToken =  currentDate > expirationDate;
      }
      return this.isToken;

    });
  }

  isLoggedIn() {
    return true;
  }

  async getStatuses(): Promise<Status[]> {
    return (await this.query({__order_by: 'code'}, 'status', 'order')).data;
  }

  async getCompanyData() {
    try {
      return (await this.query({}, 'company', 'auth')).data[0];
    } catch (e) {
      return null;
    }
  }

  async getCompanyConfig(id?: any) {
    try {
      return (await this.query({__company_id__equal: id, __only: ['key', 'value']},
        'company_config', 'auth')).data;
    } catch (e) {
      return null;
    }
  }

  async getCustomer(name, mobile) {
    const query = {
      __include: ['id', 'name', 'email'], __is_active__bool: true
    }
    query['__mobile_number__equal'] = mobile;
    // query['__name__contains'] = name;
    return (await this.https.query(query, 'customer', 'auth')).data[0];
  }
  async getMerchant(merchantId) {
    this.merchantInfo = (await this.https.get(merchantId, {}, 'merchant', 'auth'));
  }

  async getMerchantWallet(merchantId) {
    try {
      if (!merchantId)
        return;
      this.merchantWallet = (await this.https.query({__merchant_id__equal: merchantId},
        'merchant_wallet', 'auth')).data[0];
    } catch (e) {
      console.error(e)
    }
  }

  async checkMerchantWalletAccess(merchantId?) {
    if (!this.enableMerchantWallet) {
      this.companyData = await this.getCompanyData()
      if (this.companyData && this.companyData['id']) {
        this.companyConfig = await this.getCompanyConfig(this.companyData['id']);
        this.companyConfig.forEach(row => {
          if (row['key'] === 'merchant_wallet_access' && row['value'] === 1) {
            this.enableMerchantWallet = true;
            let id = null;
            if (merchantId) {
              id = merchantId
            } else {
              id = (this.user.merchant && this.user.merchant.id) ? this.user.merchant.id : this.merchantInfo['id'];
            }
            this.getMerchantWallet(id).then();
          }
        });
      }
    }
  }
}
