import {Component, Input, OnInit} from '@angular/core';
import {Category} from "../../../@core/models/Category";
import {AuthService, ToastService} from "../../../@core/utils";

@Component({
  selector: 'app-category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss']
})
export class CategorySelectionComponent implements OnInit {
  @Input() serial: string;
  @Input() userId: number;
  @Input() selectedCategory: Category[] = [];

  categories: Promise<any>;

  constructor(private http: AuthService, private toast: ToastService) {
  }

  ngOnInit() {
    this.getCategories();
  }


  getCategories() {
    this.categories = this.http.query({}, 'category');
  }

  checkStatus(id: string): boolean {
    return this.selectedCategory ? this.selectedCategory.findIndex(c => c.id === id) > -1 : false;
  }

  async changeStatus(id: number, event: any) {
    try {
      await this.http.create({
        __action: event ? 'add' : 'remove',
        category_id: id,
        user_id: this.userId
      }, {}, 'user_category_association');
      this.toast.showToast('Successfully ' + event ? 'added' : 'removed' + ' category', 'Success', false);
    } catch (e) {
      this.toast.showToast('Error adding category ', 'Error', true, e);
    }

  }

}
