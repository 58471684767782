import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address, Geom} from "../../../@core/models/User";
import {DataService, ToastService} from "../../../@core/utils";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges {

  @Input()
  address: Address;
  @Input()
  id: string;
  @Input()
  path: string;
  @Input()
  serial: string;

  constructor(private http: DataService, private toaster: ToastService) {
  }



  ngOnChanges(): void {
    if (!this.address) {
      this.address = <Address>{geom: {} as Geom};
    }
  }

  async save() {
    try {
      if (this.address.id) {
        await this.http.update(this.address.id, this.address, {}, 'address', 'auth');
      } else {
        const res = await this.http.create(this.address, {__only: 'id'}, 'address', 'auth');
        this.address.id = res[0].id;
        await this.http.update(this.id, {address_id: this.address.id}, {}, this.path ? this.path : 'outlet', 'auth');
      }
      this.toaster.showToast('Saved address successful', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error saving address', 'Error', true, e);
    }
  }

  setAddress(event: Address) {
    this.address.google_address = event.google_address;
    this.address.geom.latitude = event.geom.latitude;
    this.address.geom.longitude = event.geom.longitude;
    this.address.city = event.city;
    this.address.state = event.state;
    this.address.country = event.country;
  }

  ngOnInit(): void {
  }


}
