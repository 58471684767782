<nb-card>
  <nb-card-header>{{serial}}. Map View</nb-card-header>
  <nb-card-body class="p-4">
    <div
      (leafletMapReady)="onMapReady($event)"
      [leafletOptions]="options"
      leaflet style="min-height: 500px">

      <div [leafletDrawOptions]="drawOptions"
           leafletDraw>
      </div>
    </div>

  </nb-card-body>
</nb-card>
